<template>
    <div class="menu-content">
        <main-nav-header></main-nav-header>
        <div class="content">
            <div class="row row-cols-1 mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

            <div class="row row-cols-2 mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

            <div class="row row-cols-3 mb-4">
                <div class="col" v-for="(n,index) in 3" :key="index">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

            <div class="row row-cols-4 mb-4">
                <div class="col" v-for="(n,index) in 4" :key="index">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>

                <div class="col-3" v-for="(n,index) in 2" :key="index">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>

                <div class="col-3">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>

                <div class="col-4">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-4">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>

                <div class="col">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                    </layout-box>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import MainNavHeader from "../../../src/components/MainNavHeader/MainNavHeader";
    import LayoutBox from "../../../src/components/LayoutBox/LayoutBox";

    export default {
        name: "PageLayouts",
        components: {
            MainNavHeader,
            LayoutBox
        },
        mounted() {
            this.$emit('toggleMenu','main', false);
        },
    }
</script>

